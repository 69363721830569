<script>
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {ref} from "vue";
import countries from "@/assets/countries.csv";

console.log(countries);
export default {
  setup() {
    const store    = useStore();
    const route    = useRoute();
    const router   = useRouter();
    const email    = ref("");
    const password = ref("");
    const country  = ref("");
    const type     = ref("");
    const id       = route.params.id;
    const showSuccess = ref(null);
    const showError   = ref(null);
    
    if(!id){
      router.push({name : "404"});
    }

    store.dispatch("getUser",id)
    .then(res => {
      console.log(res.data);
      email.value   = res.data.email;
      country.value = res.data.country;
      type.value    = res.data.type;
    })
    .catch(e => console.log(e));

    const makeObject = () => {
      // const formData = new FormData();
      // formData.append("id", id);
      // formData.append("password", password.value);
      // formData.append("country", country.value);
      // formData.append("type", type.value);
      // return formData;
      return {
        id,
        password : password.value,
        country : country.value,
        type : type.value
      }
    }

    const updateUser = () => {
      const obj = makeObject();
      store.dispatch("updateUser", obj)
      .then( () => {
        showSuccess.value = true;
        setTimeout(() => showSuccess.value = null, 5000)
      })
      .catch( e => {
        console.log(e);
        showError.value = true;
        setTimeout(() => showError.value = null, 5000)
      });
    }

    const deleteUser = () => {
      let confirm = window.confirm("deseas eliminar a " + email.value + "?");
      if(confirm){
        store.dispatch("deleteUser", id)
        .then( () => {
          router.push({
            name : "UsersAdmin"
          });
        })
        .catch( e => {
          console.log(e);
        });
      }
    }

    return {
      countries,
      email,
      password,
      country,
      type,
      showSuccess,
      showError,
      updateUser,
      deleteUser
    };
  },
};
</script>

<template>
  <div>
    <h3>Actualizar usuario: {{ email }} </h3>
    <div class="ip_separator bottom"></div>
    <form @submit.prevent="updateUser" class="mt-4">
      <transition name="fade">
      <p v-if="showSuccess" class="ob_alert success">el usuario se ha actualizado</p>
    </transition>

    <transition name="fade">
      <p v-if="showError" class="ob_alert danger">el usuario no se actualizó :( </p>
    </transition>
    <div class="row">
      <div class="col-sm-6">
      <p>
        <label>correo:</label>
        <input type="email" disabled placeholder="correo" required v-model="email" />
      </p>
      </div>
      <div class="col-sm-6">
      <p>
        <label>contraseña:</label>
        <input type="password" placeholder="contraseña" v-model="password" />
      </p>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <p>
        <label>país:</label>
        <select v-model="country">
          <option
            v-for="country of countries"
            :key="`opt-${country.id}`"
            :value="country.id"
          >
            {{ country.name }}
          </option>
        </select>
      </p>
      </div>
      <div class="col-sm-6">
        <p>
        <label>tipo:</label>
        <select v-model="type">
          <option value="0">usuario</option>
          <option value="1">administrador</option>
        </select>
      </p>
      </div>
    </div>
    
    <div class="ip_separator bottom"></div>
      
      <div class="row">
        <div class="col-sm-8">
          <h4>Eliminar usuario</h4>
          <p>Si decides elimnar usuario, toma en cuenta que esta es una acción que no se puede deshacer.<br>
          <button @click.prevent="deleteUser">Eliminar usuario</button> </p>
        </div>
        
      </div>
      
     
      <div class="ip_separator bottom"></div>
      <p>
        <input type="submit" value="Guardar" />
      </p>
    </form>
  </div>
</template>